<template>
    <div class="container" id="CompetitionDetail">
        <el-page-header @back="$router.back()" :content="['创建赛事','编辑赛事','查看赛事信息','补充历史赛事'][type]"></el-page-header>
        <div class="CompetitionDetail_content">
            <el-alert
                title="审核不通过原因："
                :description="(AuditReason || '')"
                type="warning"
                effect="dark"
                show-icon
                :closable="false"
                style="margin-bottom:30px"
                 v-if="LeagueNowStatus == 6">
            </el-alert>
            <el-form ref="form" :model="form" :disabled="type == 2" :rules="rules" label-width="100px" size="small">
                <el-form-item label="运动项目:">{{form.eventsName}}</el-form-item>
                <el-form-item label="赛季名称:" prop="seasonName">
                    <el-input v-model.trim="form.seasonName" maxlength="10" placeholder="请输入赛季名称" style="width:100%;max-width:400px"></el-input>
                    <p style="line-height:20px;margin-top:5px;font-size:12px">示例：2022赛季 2022~2023赛季 2022年 2022年度 首届 第20届 第十五届</p>
                    <p style="line-height:20px;font-size:12px">注意：赛季名称不能重复。</p>
                </el-form-item>
                <!-- <el-form-item label="运动项目:" prop="eventsId">
                    <el-select v-model="form.eventsId" placeholder="请选择">
                        <el-option
                            v-for="(item,index) in eventsList"
                            :key="index"
                            :label="item.Name"
                            :value="item.Id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="赛事全称:" prop="name">
                    <el-input v-model.trim="form.name" maxlength="50" placeholder="请输入赛事全称" style="width:100%;max-width:400px"></el-input>
                    <p style="line-height:20px;margin-top:5px;font-size:12px">可含有年度/赛季/届/举办地/赞助商等信息</p>
                </el-form-item>
                <el-form-item label="赛事简称:" prop="shortName">
                    <el-input v-model.trim="form.shortName" maxlength="32" placeholder="请输入赛事简称" style="width:100%;max-width:400px"></el-input>
                </el-form-item>
                <el-form-item label="比赛形式:" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio
                            :label="1"
                            border>线上赛</el-radio>
                        <el-radio
                            :label="2"
                            border>线下赛</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="比赛平台url:" prop="leagueSite" v-if="form.type == 1">
                    <el-input v-model.trim="form.leagueSite" placeholder="请输入平台url" style="width:100%;max-width:400px"></el-input>
                </el-form-item>
                <el-form-item label="赛事logo:">
                    <div class="uploadImg logo">
                        <div class="img_box">
                            <div class="img" v-show="form.logoUrl || form.preLogoUrl">
                                <img :src="form.logoUrl || form.preLogoUrl">
                                <div class="state"></div>
                            </div>
                            <div class="add" v-if="type != 2">
                                <i class="el-icon-plus"></i>
                                <input type="file" ref="file" accept="image/*" @change="imgUpload(1,$event)">
                            </div>
                        </div>
                        <div class="el-upload__tip" v-if="type != 2">
                            <p>只能上传jpg/png格式，大小不超过 200KB</p>
                            <p>建议分辨率：42×42</p>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="封面照片:" prop="surfacePlotUrl" >
                    <div class="uploadImg surfacePlot">
                        <div class="img_box">
                            <div class="img" v-show="form.surfacePlotUrl || form.preSurfacePlotUrl">
                                <img :src="form.surfacePlotUrl || form.preSurfacePlotUrl">
                                <div class="state"></div>
                            </div>
                            <div class="add" v-if="type != 2">
                                <i class="el-icon-plus"></i>
                                <input type="file" ref="file" accept="image/*" @change="imgUpload(2,$event)">
                            </div>
                        </div>
                        <div class="el-upload__tip" v-if="type != 2">
                            <p>只能上传jpg/png格式，长宽比例：375×211，大小不超过 1MB</p>
                            <p>建议大小 0.5MB，分辨率：1080×608 或 1440×810</p>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="特殊状态:" prop="abnormalStatus">
                    <el-radio-group v-model="form.abnormalStatus">
                        <el-radio :label="0">无（正常）</el-radio>
                        <el-radio :label="1">赛事取消</el-radio>
                        <el-radio :label="2">赛事延期</el-radio>
                        <el-radio :label="3">赛事中断</el-radio>
                    </el-radio-group>
                </el-form-item>
                <template v-if="form.abnormalStatus > 0">
                    <el-form-item label="公告链接:" prop="bulletinLink">
                        <el-input v-model="form.bulletinLink" placeholder="请输入公告链接" style="width:100%;max-width:400px" @blur="bulletinLinkBlur"></el-input>
                    </el-form-item>
                    <el-form-item label="公告标题:" prop="bulletinTitle">
                        <el-input v-model="form.bulletinTitle" maxlength="50" placeholder="请输入公告标题" style="width:100%;max-width:400px"></el-input>
                    </el-form-item>
                </template>
                <el-form-item label="比赛时间:" :prop="form.abnormalStatus == 0 ? 'time' : ''">
                    <el-date-picker
                        v-model="form.time"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-ddTHH:mm:ss">
                    </el-date-picker>
                </el-form-item>
                <el-card shadow="never" style="width:100%;max-width:650px;box-sizing: border-box;margin-bottom:20px">
                    <el-form-item label="已添加地点:" label-width="150px" prop="address" style="margin-bottom:0" :inline-message="true">
                        <div style="width:100%;max-width:500px">
                            <el-tag
                                v-for="(item,index) in form.address" :key="index"
                                :closable="type != 2"
                                disable-transitions effect="plain"
                                @close="removedAddress(index)" 
                            >
                                <span>{{item.RegionName}}</span>
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item :required="form.type == 2" label-width="150px" style="margin-bottom:0">
                        <template slot="label">
                            比赛地点<span style="color:#409EFF;font-weight:blod">{{form.address.length + 1}}</span>:
                        </template>
                        <el-form :disabled="type == 2" size="small" >
                            <el-form-item style="margin-bottom:0">
                                <div style="display:inline-block;width:calc(100% - 90px);max-width:400px">
                                    <el-select v-model="addressValue[0]" @change="getCity" placeholder="请选择省" style="width:33%;">
                                        <el-option v-for="(item,index) in provinceList" :key="index" :value="index" :label="item.province"></el-option>
                                    </el-select>
                                    <el-select v-model="addressValue[1]" @change="getArea" placeholder="请选择市" style="width:33%;">
                                        <el-option v-for="(item,index) in cityList" :key="index" :value="index" :label="item.city"></el-option>
                                    </el-select>
                                    <el-select v-model="addressValue[2]" placeholder="请选择区县" style="width:33%;">
                                        <el-option v-for="(item,index) in areaList" :key="index" :value="index" :label="item.area"></el-option>
                                    </el-select>
                                </div>
                                <el-button type="primary" style="margin-left:10px" @click="addAddress()" title="点击添加比赛地点"><i class="el-icon-plus"></i> 添加</el-button>
                            </el-form-item>
                        </el-form>
                    </el-form-item>
                </el-card>
                <el-card shadow="never" v-for="(unitItem,unitIndex) in form.units" :key="unitIndex" style="width:100%;max-width:650px;box-sizing: border-box;margin-bottom:20px">
                    <el-form-item :label="`已添加${unitItem.name}:`" label-width="150px" :prop="`units.${unitIndex}.list`"
                        :inline-message="true"
                        :rules="unitItem.required ? [
                            {required:true,validator:(rule, value, callback)=>{
                                if(!value || value.length == 0){
                                    callback('请添加'+unitItem.name);
                                }else{
                                    callback();
                                }
                            }}
                        ] : null" 
                        style="margin-bottom:0"
                    >
                        <div style="width:100%;max-width:500px">
                            <el-tag
                                v-for="(item,index) in unitItem.list" :key="index"
                                :closable="type != 2"
                                disable-transitions effect="plain"  
                                @close="removeUnit(unitIndex,index)" 
                            >
                                <span>{{item}}</span>
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item :required="unitItem.required" label-width="150px" style="margin-bottom:0">
                        <template slot="label">
                            {{unitItem.name}}<span style="color:#409EFF;font-weight:blod">{{unitItem.list.length + 1}}</span>:
                        </template>
                        <el-input v-model.trim="unitItem.value" :placeholder="unitItem.name + '名称'" style="width:calc(100% - 90px);max-width:400px"></el-input>
                        <el-button type="primary" style="margin-left:10px" @click="addUnit(unitIndex)"  :title="'点击添加'+unitItem.name"><i class="el-icon-plus"></i> 添加</el-button>
                    </el-form-item>
                </el-card>
                <el-form-item label="赛事详情:" prop="detail">
                    <Editor v-model="form.detail" :oss-options="this.UploaderOptions" :class="{'CompetitionDetail_editor':type == 2}"></Editor>
                </el-form-item>
                <el-divider></el-divider>
                <div style="margin-bottom:20px">赛事分组信息</div>
                <template v-if="form.competitions.length > 0">
                    <el-card shadow="never" v-for="(competitionItem,competitionIndex) in form.competitions" :key="competitionIndex+'1'" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                        <el-form-item label="比赛项目:" :prop="`competitions.${competitionIndex}.eventsId`" :rules="competitionRules.eventsId" label-width="100px">
                            <el-row type="flex" justify="space-between">
                                <el-select v-model="competitionItem.eventsId" placeholder="请选择">
                                    <el-option v-for="(item,index) in groupForm.eventsList" :key="index" 
                                    :value="item.Id"
                                    :label="item.Name"
                                    :disabled="item.Id != competitionItem.eventsId && repetition(item.Id)"></el-option>
                                </el-select>
                                <el-button type="danger" @click="removeGroupEvent(competitionIndex)" v-show="form.competitions.length > 1"><i class="el-icon-minus"></i> 删除此比赛项目</el-button>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="已添加组别:" :prop="`competitions.${competitionIndex}.groups`" :rules="competitionRules.groups" label-width="100px" style="width:100%;" >
                            <el-tag
                                v-for="(item,index) in competitionItem.groups" :key="index"
                                :closable="type != 2"
                                disable-transitions effect="plain"
                                @close="removeGroup(competitionIndex,index,competitionItem.eventsId)" 
                            >
                                <span>{{item.name}}（{{getGroupTypeText(item.type).text || ' - '}}{{item.isVip?'，嘉宾':''}}）</span>
                            </el-tag>
                        </el-form-item>
                        <el-form-item label="快捷添加:" style="width:100%;margin-bottom:10px" v-show="groupForm.presetGroup[competitionItem.eventsId]">
                            <div style="max-height:100px;overflow-y: auto;">
                                <el-checkbox-group v-model="groupForm.checkList">
                                    <el-checkbox v-for="(item,index) in groupForm.presetGroup[competitionItem.eventsId]" :key="index"
                                        :label="competitionItem.eventsId + item.name"
                                        @change="presetGroup($event,competitionIndex,item)"
                                        style="height:24px"
                                    >{{item.name}}（{{getGroupTypeText(item.type).text || ' - '}}{{item.isVip?'，嘉宾':''}}）</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-form-item>
                        <el-divider class="divider" content-position="left" >组别 <span style="color:#409EFF;font-weight:blod">{{competitionItem.groups.length+1}}</span> 信息填写</el-divider>
                        <div>
                            <el-form ref="groupForm" :model="groupForm" :disabled="type == 2" label-width="100px" size="small">
                                <el-form-item label="组别名称:" :prop="`groupValues.${competitionIndex}.name`" :rules="groupRules.name" >
                                    <el-input v-model.trim="groupForm.groupValues[competitionIndex].name" maxlength="50" placeholder="请输入组别名称" style="width:100%;max-width:400px"></el-input>
                                </el-form-item>
                                <el-form-item label="组别类型:" :prop="`groupValues.${competitionIndex}.type`" :rules="groupRules.type">
                                    <el-radio-group v-model="groupForm.groupValues[competitionIndex].type">
                                        <el-radio v-for="(item,index) in groupForm.groupTypeList" :key="index" 
                                        :label="item.type"
                                        border>{{item.text}}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-button type="primary" size="small" @click="addGroup(competitionIndex)" style="margin-left:100px" title="点击添加组别"><i class="el-icon-plus"></i> 添加组别</el-button>
                            </el-form>
                        </div>
                    </el-card>
                </template>
                <el-row style="margin-top:10px" v-show="groupForm.eventsList.length > form.competitions.length">
                    <el-button type="primary" size="medium" style="width:150px" @click="addGroupEvent"><i class="el-icon-plus"></i> 添加比赛项目</el-button>
                </el-row>
            </el-form>
            <el-divider></el-divider>
            <el-row style="margin:30px 0 20px 100px"  v-if="type != 2">
                <el-button type="success" style="width:100px" @click="save">保存</el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import {
    GetEditSubleagueInitInfo,
    GetSubleagueDetail,
    CreateSubleague,
    EditSubleague
} from '@/api'
import {post} from '@/api/oldApi';
import OSS from 'ali-oss'
import { nanoid } from "nanoid"
import Editor from '@/components/Editor';
export default {
    components: { 
        Editor
    },
    data(){
        var id = parseInt(this.$route.params.subLeagueId),
            type = parseInt(this.$route.params.type);
        return {
            type: isNaN(type) ? 0 : type,//0：创建 1：修改 2：查看 3：补充历史赛事
            Id: isNaN(id) ? 0 : id,
            seasonType:0,
            form:{
                seasonName:"",
                seasonId:0,
                eventsId:null,
                eventsName:"",
                name:"",
                shortName:"",
                type:2,
                leagueSite: "",
                logoUrl:"",
                preLogoUrl:"",
                surfacePlotUrl:"",
                preSurfacePlotUrl:"",
                abnormalStatus: 0,
                bulletinLink: "",
                bulletinTitle: "",
                address:[],
                time:[],
                units:[{
                        name:'主办单位',
                        required:true,
                        value:'',
                        list:[]
                    },{
                        name:'指导单位',
                        value:'',
                        list:[]
                    },{
                        name:'支持单位',
                        value:'',
                        list:[]
                    },{
                        name:'承办单位',
                        required:true,
                        value:'',
                        list:[]
                    },{
                        name:'运营单位',
                        value:'',
                        list:[]
                    },{
                        name:'协办单位',
                        value:'',
                        list:[]
                    },{
                        name:'赞助单位',
                        value:'',
                        list:[]
                    }
                ],
                detail:"",
                competitions:[]
            },
            rules:{
                seasonName:[
                    {required:true,message:"请输入赛季全称",trigger: 'blur'},
                    {max:10,message:"赛季名称最长10个字符",trigger: 'blur'}
                ],
                eventsId:[
                    {required:true,message:"请选择运动项目",trigger: 'change'}
                ],
                name:[
                    {required:true,message:"请输入赛事名称",trigger: 'blur'},
                    {max:50,message:"赛事名称最长50个字符",trigger: 'blur'}
                ],
                shortName:[
                    {max:32,message:"赛事简称最长32个字符",trigger: 'blur'}
                ],
                type:[
                    {required:true,message:"请选择比赛形式",trigger: 'change'}
                ],
                surfacePlotUrl:[
                    {required:true,message:"请上传赛事封面"}
                ],
                abnormalStatus:[
                    {required:true,message:"请选择特殊状态"}
                ],
                bulletinLink:[
                     {validator:(rule, value, callback)=>{
                        if(value){
                            if(!(/^http|https/.test(value))){
                                callback(new Error('必须是一个以http或https开头的有效链接'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    }, trigger: 'blur'}
                ],
                bulletinTitle:[
                     {validator:(rule, value, callback)=>{
                        if(this.form.bulletinLink && !value){
                            callback(new Error('请输入公告标题'));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur'},
                    {max:50,message:"公告标题最长50个字符",trigger: 'blur'}
                ],
                time:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0){
                            callback(new Error('请选择比赛时间'));
                        }else{
                            callback();
                        }
                    }, trigger: 'change'}
                ],
                address:[
                    {validator:(rule, value, callback)=>{
                        if(this.form.type == 2 && (!value || value.length == 0)){
                            callback(new Error('请添加比赛地点'));
                        }else{
                            callback();
                        }
                    }}
                ],
                detail:[
                    {required:true,message:"请输入赛事详情"}
                ]
            },
            eventsList:[],
            addressValue:new Array(3),
            provinceList:null,
            cityList:null,
            areaList:null,
            competitionRules:{
                eventsId:[
                    {required:true,message:"请选择比赛项目",trigger: 'change'}
                ],
                groups:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0){
                            callback(new Error('请添加组别'));
                        }else{
                            callback();
                        }
                    }}
                ]
            },
            groupForm:{
                eventsList:[],
                presetGroup:{},
                checkList:[],
                groupTypeList:[{
                    type:1,
                    text:'个人'
                },{
                    type:2,
                    text:'团体'
                },{
                    type:3,
                    text:'双人组合'
                }],
                groupValues:[]
            },
            groupRules:{
                name:[
                    {required:true,message:"请输入组别名称",trigger: 'blur'},
                    {max:50,message:"组别名称最长50个字符",trigger: 'blur'}
                ],
                type:[
                    {required:true,message:"请选择组别类型",trigger: 'change'}
                ]
            },
            UploaderOptions:{},
            AuditReason:"",
            LeagueNowStatus:null,
            isOption: true
        }
    },
    created(){
        GetEditSubleagueInitInfo({
            SubLeagueId: this.Id
        }).then(data=>{
            if(data.Code == 0){
                this.eventsList = data.EventsList;
                this.UploaderOptions = data.UploaderOptions;
                if(data.PresetGroupList){
                    let presetGroupList = data.PresetGroupList,
                        presetGroupObj = {};
                    for(var i=0;i<presetGroupList.length;i++){
                        let obj = presetGroupList[i];
                        let groupObj = {
                            name: obj.GroupName,
                            type: obj.GroupType,
                            isVip: obj.IsHonored
                        };
                        if(presetGroupObj[obj.EventsId]){
                            presetGroupObj[obj.EventsId].push(groupObj);
                        }else{
                            presetGroupObj[obj.EventsId] = [groupObj];
                        }
                    }
                    this.groupForm.presetGroup = presetGroupObj;
                }
                if(this.type == 0 || this.type == 3){
                    this.Id = data.NewSubLeagueId;
                    var obj = data.EventsList[0];
                    this.form.eventsId = obj.Id;
                    this.form.eventsName = obj.Name;
                    this.setGroupEvent(2,obj.Id);
                }else{
                    if(this.form.eventsId){
                        this.setGroupEvent(1,this.form.eventsId);
                    }
                }
            }
        })
        if(this.type != 0 && this.type != 3){
            GetSubleagueDetail({
                SubLeagueId: this.Id
            }).then(data=>{
                if(data.Code == 0){
                    var editInfo = data.EditInfo;
                    this.LeagueNowStatus = editInfo.LeagueNowStatus;
                    this.AuditReason = editInfo.AuditReason;
                    this.seasonType = editInfo.SeasonType;
                    var form = this.form;
                    form.seasonName = editInfo.SeasonName;
                    form.seasonId = editInfo.SeasonId;
                    form.eventsId = editInfo.EventsId;
                    form.eventsName = editInfo.EventsName;
                    form.name = editInfo.LeagueFullName;
                    form.shortName = editInfo.LeagueShortName;
                    form.type = editInfo.IsOnline ? 1 : 2;
                    form.leagueSite = editInfo.LeagueSite;
                    form.logoUrl = editInfo.LogoUrl;
                    form.surfacePlotUrl = editInfo.CoverUrl;
                    form.abnormalStatus = editInfo.AbnormalStatus;
                    form.bulletinLink = editInfo.BulletinUrl;
                    form.bulletinTitle = editInfo.BulletinTitle;
                    form.address = editInfo.GamePlace || [];
                    form.time = editInfo.GameTime ? [editInfo.GameTime.BeginTime,editInfo.GameTime.EndTime] : [];
                    let {
                        Assist,
                        Host,
                        GuideUnit,
                        Operator,
                        Sponsor,
                        Supporter,
                        Undertaker
                    } = editInfo.Units;
                    let units = form.units;
                    units[0].list = Host ? Host.split(' ') : [];
                    units[1].list = GuideUnit ? GuideUnit.split(' ') : [];
                    units[2].list = Supporter ? Supporter.split(' ') : [];
                    units[3].list = Undertaker ? Undertaker.split(' ') : [];
                    units[4].list = Operator ? Operator.split(' ') : [];
                    units[5].list = Assist ? Assist.split(' ') : [];
                    units[6].list = Sponsor ? Sponsor.split(' ') : [];
                    this.$set(this.form,'units',units);
                    form.detail = editInfo.Comment;
                    let groupList = editInfo.GroupList;
                    let competitions = [];
                    let groupValues = [];
                    for(var i=0;i<groupList.length;i++){
                        let obj = groupList[i];
                        let groupObj = {
                            name: obj.GroupName,
                            type: obj.GroupType,
                            isVip: obj.IsHonored
                        };
                        let index = -1;
                        for(var j=0;j<competitions.length;j++){
                            if(competitions[j].eventsId == obj.EventsId){
                                index = j;
                                break;
                            }
                        }
                        if(index > -1){
                            competitions[index].groups.push(groupObj);
                        }else{
                            competitions.push({
                                eventsId: obj.EventsId,
                                groups: [groupObj]
                            })
                            groupValues.push({
                                name: "",
                                type: 2,
                                isVip: 0
                            })
                        }
                    }
                    form.competitions = competitions;
                    this.$set(this.groupForm,'groupValues',groupValues);
                    if(this.eventsList.length > 0){
                        this.setGroupEvent(1,editInfo.EventsId);
                    }
                }
            })
        }
        this.getProvince();
    },
    watch:{
        'form.eventsId':{
            deep:true,
            handler(newVal,oldVal){
                if(oldVal > 0){
                    this.$set(this.form,'competitions',[]);
                    this.$set(this.groupForm,'groupValues',[]);
                    this.addGroupEvent();
                }
            }
        },
        'form.detail':{
            deep:true,
            handler(newVal,oldVal){
                if(this.$refs['form']){
                    this.$refs['form'].validateField(`detail`);
                }
            }
        }
    },
    methods:{
        bulletinLinkBlur(){
            var value = this.form.bulletinLink;
            if((/^http|https/.test(value))){
                var _this = this,
                    iframe = document.createElement('iframe'),
                    is = true;
                iframe.id = 'iframe';
                iframe.src = value;
                iframe.style.display = "none";
                document.body.appendChild(iframe);
                window.addEventListener('message', function (e) {
                    if(!is)return;
                    _this.form.bulletinTitle = e.data;
                    document.body.removeChild(document.getElementById('iframe'));
                    is = false;
                })
                iframe.onload = function(){
                    if(!is)return;
                    if(iframe.contentWindow.document.title){
                        _this.form.bulletinTitle = iframe.contentWindow.document.title;
                        document.body.removeChild(document.getElementById('iframe'));
                        is = false;
                    }
                }
            }
        },
        addUnit(index){
            let {value,list,required} = this.form.units[index];
            if(value){
                list.push(value);
                this.$set(this.form.units[index],'list',list);
                this.$set(this.form.units[index],'value','');
                if(required){
                    this.$refs['form'].validateField(`units.${index}.list`);
                }
            }
        },
        removeUnit(index,deleteIndex){
            let {list,required} = this.form.units[index];
            list.splice(deleteIndex,1);
            this.$set(this.form.units[index],'list',list);
            if(required){
                this.$refs['form'].validateField(`units.${index}.list`);
            }
        },
        imgUpload(type,event){
            let files = event.target.files, file;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr =  ['image/jpeg','image/png','image/bmp'];
                if(acceptArr.indexOf(file.type) == -1){
                    this.$message.error('不支持此文件类型');
                    return;
                }
                if (type == 1 && file.size > 200*1024) {
                    this.$message.error('图片大小不能超过 200KB，请压缩后上传');
                    return;
                }else if (type == 2 && file.size > 1*1024*1024) {
                    this.$message.error('图片大小不能超过 1MB，请压缩后上传');
                    return;
                }
                this.ossUpload({
                    fileData: file,
                    type
                })
            }
        },
        ossUpload({fileData,type}){
            var img_src = URL.createObjectURL(fileData);
            var _this = this;
            let dom = ""
            if(type == 1){
                _this.form.logoUrl = '';
                _this.form.preLogoUrl = img_src;
                dom = ".logo .state"
            }else{
                _this.form.surfacePlotUrl = '';
                _this.form.preSurfacePlotUrl = img_src;
                dom = ".surfacePlot .state"
            }
            let loading = this.$loading({
                target: dom,
                lock: true,
                text: '图片正在上传',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            });
            const options = this.UploaderOptions;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition':encodeURIComponent(fileData.name),
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            client.put(nameID, fileData, {headers}).then(function (r1) {
                loading.close();
                if(type==1){
                    _this.form.logoUrl = r1.url;
                    _this.form.preLogoUrl = '';
                }else{
                    _this.form.surfacePlotUrl = r1.url;
                    _this.form.preSurfacePlotUrl = '';
                    _this.$refs['form'].validateField(`surfacePlotUrl`);
                }
            }).catch(function (err) {
                loading.close();
                if(type==1){
                    _this.form.logoUrl = '';
                    _this.form.preLogoUrl = '';
                }else{
                    _this.form.surfacePlotUrl = '';
                    _this.form.preSurfacePlotUrl = '';
                }
                this.$message.error('图片上传失败，请重试！');
            });
        },
        addAddress(){
            var value = this.addressValue;
            var arr = this.form.address;
            var text = '',obj={};
            if(value[0] && value[0] > -1){
                let obj_2 = this.provinceList[value[0]];
                text += obj_2.province;
                obj.ProvinceCode = obj_2.province_id;
            }
            if(value[1] && value[1] > 0){
                let obj_2 = this.cityList[value[1]];
                if(text != obj_2.city){
                    text += '，'+obj_2.city;
                }
                obj.CityCode = obj_2.city_id;
            }
            if(value[2] && value[2] > 0){
                let obj_2 = this.areaList[value[2]];
                text += '，'+obj_2.area;
                obj.AreaCode = obj_2.area_id;
            }
            if(text){
                obj.RegionName = text;
                arr.push(obj);
                this.$set(this.form,'address',arr);
                this.addressValue = new Array(3);
                this.$refs['form'].validateField(`address`);
            }
        },
        removedAddress(index){
            let address = this.form.address;
            address.splice(index,1);
            this.$set(this.form,'address',address);
            this.$refs['form'].validateField(`address`);
        },
        save(){
            //事件多次触发
            if(!this.isOption)return;
            this.isOption = false;
            setTimeout(()=>{this.isOption = true;},300);
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    var form = this.form;
                    var eventsId = form.eventsId;
                    var eventsName = this.eventsList.filter(n=>n.Id == eventsId)[0].Name;
                    var competitions = form.competitions;
                    var GroupList = [];
                    for(var i=0;i<competitions.length;i++){
                        let obj = competitions[i];
                        let groups = obj.groups;
                        let eventsId = obj.eventsId;
                        let eventsName = this.eventsList.filter(n=>n.Id == eventsId)[0].Name;
                        for(var j=0;j<groups.length;j++){
                            GroupList.push({
                                "GroupId": 0,
                                "GroupName": groups[j].name,
                                "EventsId": eventsId,
                                "EventsName": eventsName,
                                "LeagueId": this.Id,
                                "GroupType": groups[j].type,
                                "IsHonored": groups[j].isVip
                            })
                        }
                    }
                    let SeasonType = 0;
                    switch (this.type){
                        case 0:
                            SeasonType = 2;
                        break;
                        case 3:
                            SeasonType = 3;
                        break;
                        case 1:
                            SeasonType = this.seasonType;
                        break;
                        
                    }
                    let sendData = {
                        "EditInfo": {
                            "MainLeagueId": this.$store.state.leagueInfo.id,
                            "SubleagueId": this.Id,
                            "SeasonType": SeasonType,
                            "SeasonId": form.seasonId,
                            "SeasonName": form.seasonName,
                            "EventsId": eventsId,
                            "EventsName": eventsName,
                            "LeagueFullName": form.name,
                            "LeagueShortName": form.shortName,
                            "IsOnline": form.type == 1,
                            "LeagueSite": form.leagueSite,
                            "LogoUrl": form.logoUrl,
                            "CoverUrl": form.surfacePlotUrl,
                            "AbnormalStatus": form.abnormalStatus,
                            "BulletinUrl": form.bulletinLink,
                            "BulletinTitle": form.bulletinTitle,
                            "GamePlace": form.address,
                            "GameTime": {
                                "BeginTime": form.time ? form.time[0] || '2099-12-31' : '2099-12-31',
                                "EndTime":  form.time ? form.time[1] || '2099-12-31' : '2099-12-31'
                            },
                            "Units": {
                                "Host": this.form.units[0].list.join(' '),
                                "GuideUnit": this.form.units[1].list.join(' '),
                                "Supporter": this.form.units[2].list.join(' '),
                                "Undertaker": this.form.units[3].list.join(' '),
                                "Operator": this.form.units[4].list.join(' '),
                                "Assist": this.form.units[5].list.join(' '),
                                "Sponsor": this.form.units[6].list.join(' ')
                            },
                            "Comment": form.detail,
                            "GroupList": GroupList
                        }
                    }
                    let loading = this.$loading({
                        text:"保存中...",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    if(this.type == 0 || this.type == 3){
                        CreateSubleague(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('赛事创建成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }else{
                        EditSubleague(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('赛事修改成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        },
        //获取省
        getProvince() {
            post('Match/Entrance', {
                action: 'Getprovince',
                user_token: ''
            }).then((data) => {
                if(data){
                    this.provinceList = data;
                }
            }).catch(() => {

            });
        },
        //获取市
        getCity() {
            post('Match/Entrance', {
                action: 'Getcity',
                user_token: '',
                id: this.provinceList[this.addressValue[0]].province_id
            }).then((data) => {
                if(data){
                    this.cityList = data;
                    this.areaList = null;
                    let value = this.addressValue;
                    value[1] = null;
                    value[2] = null;
                    this.addressValue = value;
                }
            })
        },
        getArea() {
            post('Match/Entrance', {
                action: 'Getarea',
                user_token: '',
                id: this.cityList[this.addressValue[1]].city_id
            }).then((data) => {
                if(data){
                    this.areaList = data;
                    let value = this.addressValue;
                    value[2] = null;
                    this.addressValue = value;
                }
            })
        },
        setGroupEvent(type, id){
            var eventsList = this.eventsList;
            var obj = eventsList.filter(item=>item.Id == id)[0];
            if(id == 8 || id == 3){
                this.groupForm.eventsList = eventsList.filter(item=>[3,8].indexOf(item.Id) == -1);
            }else{
                this.groupForm.eventsList = [obj];
            }
            if(type == 2){
                this.addGroupEvent();
            }
        },
        addGroupEvent(){
            var arr = this.form.competitions;
            var eventsList = this.groupForm.eventsList;
            var obj = {};
            if(arr.length == eventsList.length){
                return false;
            }
            if(arr.length > 0){
                for(var i=0;i<eventsList.length;i++){
                    if(arr.filter(n=>n.eventsId == eventsList[i].Id).length == 0){
                        obj = eventsList[i];
                        break;
                    }
                }
            }else{
                obj = eventsList[0];
            }
            arr.push({
                eventsId: obj.Id,
                groups:[]
            })
            this.$set(this.form,'competitions',arr);
            var groupValues = this.groupForm.groupValues;
            groupValues.push({
                name: "",
                type: 2
            })
            this.$set(this.groupForm,'groupValues',groupValues);
        },
        removeGroupEvent(index){
            this.$confirm('请确认删除此比赛项目').then(() => {
                var arr = this.form.competitions;
                arr.splice(index,1);
                this.$set(this.form,'competitions',arr);
            })
        },
        addGroup(index){
            this.$refs['groupForm'][index].validate((valid)=>{
                if(valid){
                    let {name,type} = this.groupForm.groupValues[index]; 
                    let groups = this.form.competitions[index].groups;
                    var isVipArr = ['嘉宾','干部'];
                    groups.push({
                        name,
                        type,
                        isVip: isVipArr.filter(n=>(name.indexOf(n) > -1)).length > 0 ? 1 : 0
                    });
                    this.$set(this.form.competitions[index],'groups',groups);
                    this.$set(this.groupForm.groupValues,index,{
                        name: "",
                        type: 2
                    });
                    this.$refs['form'].validateField(`competitions.${index}.groups`);
                }
            })
        },
        removeGroup(index,deleteIndex,eventsId){
            var groups = this.form.competitions[index].groups;
            var obj = groups.splice(deleteIndex,1)[0];
            this.$set(this.form.competitions[index],'groups',groups);
            this.$refs['form'].validateField(`competitions.${index}.groups`);
            let is = true;
            for(var i=0;i<groups.length;i++){
                if(groups[i].name == obj.name){
                    is = false;
                    break;
                }
            }
            if(is){
                let checkList = this.groupForm.checkList;
                for(var i=0;i<checkList.length;i++){
                    if(checkList[i] == eventsId + obj.name){
                        checkList.splice(i,1);
                        i--;
                    }
                }
                this.$set(this.groupForm,'checkList',checkList);
            }
        },
        presetGroup(checked,index,item){
            var groups = this.form.competitions[index].groups;
            var {name,type,isVip} = item; 
            if(checked){
                groups.push({
                    name,
                    type,
                    isVip
                })
            }else{
                for(var i=0;i<groups.length;i++){
                    if(groups[i].name == name){
                        groups.splice(i,1);
                        i--;
                    }
                }
            }
            this.$set(this.form.competitions[index],'groups',groups);
            this.$refs['form'].validateField(`competitions.${index}.groups`);
        },
        repetition(id){
            return this.form.competitions.filter(n=>n.eventsId == id).length > 0;
        },
        getGroupTypeText(type){
            return this.groupForm.groupTypeList.filter(n=>n.type==type)[0] || {};
        }
    }
}
</script>
<style scoped>

</style>
<style>
    
#CompetitionDetail .el-card .el-card__body{
    padding: 10px !important;
}
#CompetitionDetail .CompetitionDetail_editor .tox-editor-header{
    display: none ;
}

#CompetitionDetail .el-tag{
    margin-right:10px;
    margin-bottom:5px;
    max-width: 100%;
}
#CompetitionDetail .el-tag>span{
    width:calc(100% - 10px);
    display: inline-block;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    vertical-align: top;
}
#CompetitionDetail .el-tag>i{
    margin-left: -5px;
}
.el-upload__tip{
    line-height: 2em;
}
</style>